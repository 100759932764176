.skeleton-placeholder {
    background: linear-gradient(90deg, #E9EBFF 0px, #f8f8f8 40px, #E9EBFF 80px);
    background-size: 400px;
    width: 100%;
    height: 1.45rem;
    border-radius: 20px;
    margin-top: .2rem;
    animation: animation 2.5s infinite;
    padding:0 15px;
  }

  .skeleton-placeholder em{
    font-size: 12px;
    color: #6979F8
  }

@keyframes animation {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 390px;
  }
}