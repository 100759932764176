.home_body {
  padding: 16px;
  height: 84vh;
  overflow-y: scroll;
  /* margin-top: 40px; */
}

.home_pintel_message {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 4px;
  align-items: center;
  /* margin-top: 40px; */
}

.home_pintel_message img {
  width: 24px;
  height: 24px;
}

.home_message_options{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.home_message_options span{
  font-size: 14px;
}

.home_btns_container{
  display: inline-flex;
  gap: 8px;
  padding: 16px 0 ;
  flex-wrap: wrap;

}