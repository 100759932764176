.stepper-box{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0!important;
    overflow-y: scroll;
    height: 60vh;
    overflow-y: scroll;
}

.cross_btn_container{
    height: 40px;
    background: transparent;
    display: flex;
    justify-content: flex-end;
}

.dialog_content_container{
    background: white;
    z-index: 100;
    border-radius: 4px;
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    height: 100%;
}