.new_user_container{
    display: flex;
    justify-content: center;
    margin: auto;
    flex-flow: column;
    align-items: center;
    height: 80vh;
    width:80%;
    text-align: start;
}
.auth-container{
    padding: "0 20px";
    margin-top: 20px;
}
.question_container{
    display: flex;
}
.new_user_container div{
    width: 100%;
}
.title{
    margin-bottom: 0;
    color:#6979F8 !important
}
.subtitle{
    margin:5px auto 20px auto;
    font-size: 18px;
    color:#6979F8 !important
}
.question_box{
    justify-content:space-between;
    width: 7rem !important;
    background-color: #EAEEFF;
    color:#3F3356;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor:pointer
}
.question_box span{
    font-size: 12px;
    font-weight: 700;
}
.question_box p{
    font-size: 12px;
}