.loader{
  display: flex!important;
    justify-content: center!important;
    margin: auto!important;
    align-items: center!important;
    height: 100vh!important;
}


.accordion-header-summary{
  background-color: #C9CFFC!important
}
.accordion-summary{
  background-color: #EAEEFF!important
}

.title_lable{
  font-weight: 500;
  display: block;
  font-size: 15px;
  padding: 0;
}

.error_msg{
  color: rgb(242, 62, 62);
  font-size: 12px;
}

.flex_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion_answer{
  font-weight: 400;
}


.copy-icon-container {
  border-radius: 4px;
  margin-left: auto;
  text-align: left;
}



.tooltip {
visibility: hidden;
background-color: black;
color: #fff;
text-align: center;
border-radius: 5px;
position: absolute;
opacity: 0;
left: 40px;
bottom: 4px;
transition: opacity 0.3s;
max-width: 70px;
font-size: 12px;
padding: 4px;
}

/* .copy-icon-container:hover .tooltip {
visibility: visible;
opacity: 1;

} */

.copy_icon{
padding: 4px;
border-radius: 4px;
height: 18px;
width: 18px;

cursor: pointer;
/* margin-left: auto; */
}

/* .copy_icon:hover {
background-color: #f1f3ff;
} */

.copy_icon:hover .tooltip{
visibility: visible;
opacity: 1;
}

.tooltip-grid{
  visibility: hidden;
background-color: black;
color: #fff;
text-align: center;
border-radius: 5px;
position: relative;
opacity: 0;
/* left: 30px; */
/* top: 10px; */
right: 40px;
top: 20px;
transition: opacity 0.3s;
max-width: 70px;
font-size: 12px;
padding: 4px;
width: 60px;
display: block;
}

/* .copy-icon-container:hover .tooltip {
visibility: visible;
opacity: 1;

} */

.copy_icon img{
/* padding: 4px; */
border-radius: 4px;
height: 18px;
cursor: pointer;
display: block;
/* position: absolute; */
/* min-width: 50px; */
}

.copy_icon img{
 
  position: absolute;
  /* min-width: 50px; */
  }


.copy_icon:hover .tooltip-grid{
  visibility: visible;
  opacity: 1;
}

.notif-toast{
  width: "200px";


}


/* .App{
  margin-top: 32px;
} */