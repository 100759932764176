.chat-interface{
  height: 88vh;
  overflow-y: scroll;
}
.chat-interface .messages {
  /* margin-bottom: 20px; */
  /* margin-top: 20px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: 3rem;
}
.chat-interface .newUser {
  min-height: 200px;
}
.message {
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
}

.message-icon {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.message-user{
background-color: #6979F8;
color: #fff;
font-size: 13px;
max-width: 21rem;
word-break: break-word;
/* margin-top: 1.3rem; */
padding: 10px 15px;
border-radius: 10px;
border-radius: 10px 1px 10px 10px;
}

.message-text {
  font-size: 13px;
  max-width: 21rem;
  word-break: break-word;
  margin-top: 1.3rem;
  padding: 10px 15px;
  border-radius: 10px;
  border-radius: 10px 1px 10px 10px;
  border: 1px solid #dce3ff;
  text-wrap: wrap;
}

.message-data {
  font-size: 13px;
  max-width: 21rem;
  word-break: break-word;
  margin-top: 1.3rem;
  padding: 10px 15px;
  border-radius: 10px;
  border-radius: 10px 1px 10px 10px;
  border: 1px solid #dce3ff;
  text-wrap: wrap;
  text-align: left;
}

.message-data .key_title {
  font-weight: bold;
  font-size: 14px;
}

.message-data .key_content {
  font-weight: 400;
  font-size: 13px;
}

.message.user {
  text-align: right;
  align-self: flex-end;
  font-size: 14px;
  font-weight: 600;
  color: #3f3356;
  margin: "15px 5px";
}

.message.bot {
  text-align: left;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: #3f3356;
  /* margin-bottom: 50px; */
}
.bot_message {
  border-radius: 0px 10px 10px 10px;
}
.message.bot .typing {
  display: inline-block; /* Inline-block confines the width to the content */
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange; /* Cursor for typewriter effect */
  animation: typing 2s steps(40, end) forwards,
    blink-caret 0.75s step-end infinite;
}
.chat_options {
  background-color: #eaeeff;
  font-weight: 400;
  margin: 5px 5px 5px 0;
  border: none;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}
.loading-message {
  position: relative;
  bottom: -2px;
  left: 5rem;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  color: rgb(255 255 255);
  background: #776afe;
  width: 70%;
  padding: 4px 10px;
  border-radius: 9px 0 5px 9px;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.questions button {
  margin: 5px;
  padding: 10px;
}

.iframe-loader {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 46%;
  
}

.iframe-loader::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  background-color: #6979F8;

}

.iframe-loader:first-child {
  background-color: #6979F8;
}

.iframe-loader:first-child::before {
  animation-delay: 0.2s;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes ripple {
from {
  opacity: 1;
  transform: scale(0);
}
to {
  opacity: 0;
  transform: scale(8);
}
}
