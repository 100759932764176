h2.faq-heading {
  font-family: sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  color: #1d3557;
  margin: 8px 0;
  margin-top: 60px;
}
.faq-container {
  max-width: 1000px;
  border-radius: 8px;
  margin: 12px auto;
  font-family: sans-serif;
  color: #1d3557;
  line-height: 1.9;
}
.faq-container .question-container {
  margin-bottom: 10px;
  border-radius: 5px;
  background: white;
}
.faq-container .question {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #eaeeff;
  border-radius: 5px;
}
.faq-container .question .question-icon {
  width: 17px;
  height: 17px;
  background: #c9cffc;
  padding: 4px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  transition: all 300ms ease;
}
.faq-container .question-container.expanded .question-icon {
  background: #7884fe;
  color: #fff;
  transform: rotateZ(180deg);
}
.faq-container .answer ul {
  padding-left: 2rem;
  margin: 5px 5px 5px 0;
  line-height: 1.5;
}
.faq-container .answer ul > li {
  margin: 10px 0 5px 0;
}
.faq-container li {
  word-break: normal;
}
.faq-container p {
  word-break: normal;
}
.faq-container .answer {
  /* display: flex; */
  /* align-items: flex-start; */
  /* gap: 8px; */
  margin: 8px 0;
  font-weight: 300;
}
.faq-container .answer-container {
  box-shadow: 1px 3px 8px -6px gray;
  font-weight: 400;
  padding: 0px 32px;
  background: #ffffff;
  max-height: 0;
  overflow: hidden;
  transition: all 300ms ease;
  word-break: break-all;
  position: relative;
}
.faq-container .answer-container a {
  color: #1e3557;
  text-decoration: none;
  cursor: auto;
}

.faq-container .question-container.expanded .answer-container {
  /* max-height: 500px; */
  height: 100%;
  padding: 8px 10px 0;
  overflow: auto;
}
@media screen and (max-width: 480px) {
  .faq-container .question {
    font-size: 10px;
  }
  .faq-container .answer {
    font-size: 10px;
  }
  h2.faq-heading {
    font-size: 20px;
  }
}

.custom_message_block {
  background-color: #c9cffc;
  color: #ffffff;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  white-space: nowrap;
}

.custom_message_key {
  font-size: 12px;
  font-weight: bold;
}

.custom_message_value {
  font-size: 12px;
  font-weight: 400;
}

/* .copy-icon-container {
  position: absolute; 
    bottom: -20px;
    right: 10px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
} */

