.sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    left: -250px;
    transition: left 0.3s ease;
    z-index: 101;
    background-color: white;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .hamburger {
    cursor: pointer;
  }
  
  .line {
    width: 20px;
    height: 1px;
    background-color: #6979F8;
    margin: 5px 0;
  }
  .infinite-scroll-component{
    overflow: hidden !important;
  }
  .menu {
    list-style-type: none;
    padding: 0;
    background-color: #ffffff;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: auto;
    padding-inline: 8px;

  }
  .menu p{
  padding: 10px;
  font-size: 18px;
  }
  .menu li {
    /* margin-bottom: 10px; */
    font-size: 14px;
    border-bottom: 1px solid #EAEEFF;
  }
  
  .menu li a {
    display: block;
    padding: 12px;
    color: #353535;
    text-decoration: none;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
  }
  
  .menu li a:hover {
    background-color: #EAEEFF;
    margin: 5px 0;
    border-radius: 7px;
  }
  .menu li a:active {
    background-color: #6760c5;
    color: white;
  }
  .topNav{
    padding: 10px 12px;
    height: 30px;
    background-color: white;
    width: 95vw;
    z-index: 1;
    display: flex;
    align-items: center;
    /* top: 0; */
    justify-content: space-between;
  }

  .topNav .hamburger img{
    width: 18px
  }

  .navBorder{
    /* border-bottom: 0.1px solid rgb(196, 196, 196); */
    background: white;
    width: 100%;
  }
  .chat_head{
    font-size: 16px;
    font-weight: 700;
    margin: 5px;
    padding: 5px !important;
    /* border-bottom: 0.1px solid rgb(200, 200, 200); */
    margin-top: 3rem;
  }
  .newChat_btn{
    font-size: 11px !important;
    color: white;
    margin: 0 5px 20px 10px;
    padding: 8px !important;
    width: 90%;
    background-color: #6979F8;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .new_chat_icon{
    margin-right: 5px;
    width: 18px;
  }
  .sidebar_button_container{
    width: 100%;
    border-top: 2px solid #EAEEFF;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    box-sizing: border-box;
    padding: 20px;
  }